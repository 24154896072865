@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/keyframes";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".Drawer";

#{$selector}

 {
    &__header {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-end;
        padding: $space-lg 0;

        &__title {
            @include text('headline/md')
        }

        &--has-title {
            justify-content: space-between;
        }
    }

    &__content {
        @include text('base')
    }
}
