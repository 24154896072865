@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/keyframes";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".PostWidgetCarousel";

#{$selector} {
    &__carousel {
        position: relative;
        width: 100%;
        overflow: hidden;
        margin-top: $space-xl;

        &__item {
            &__image__link {
                position: relative;
                width: 100%;
                height: 200px;
                display: block;
                line-height: 0;
                border-radius: $ui-image-border-radius;
                overflow: hidden;
            }

            &__image {
                object-fit: cover;
            }

            &__link {
                position: relative;
                display: block;
            }
        }

        &__dots {
            margin-top: 0;
        }

        &__buttons {
            position: absolute;
            bottom: $space-md;
            right: $space-lg;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            min-width: 80px;
        }
    }

    &__headline {
        @include textLineClamp(2);
        width: 75%;
        margin-top: $space-md !important;
    }
}
