@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/keyframes";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".Carousel";

#{$selector} {
    position: relative;
    width: 100%;
    overflow: hidden;

    &--is-active {
        user-select: none;
    }

    &__wrapper {
        display: flex;
        transition: transform 0.5s ease-in-out;
    }

    &__item {
        flex: 1 0 auto;
    }

    &__dots {
        display: block;
        margin: $space-2xl auto 0 auto;
        text-align: center;

        &__dot {
            display: inline-block;
            width: 12px;
            height: 12px;
            background-color: #ddd;
            border-radius: 50%;
            margin: 0 5px;
            cursor: pointer;

            &--dark {
                background-color: $dark--gray;
            }

            &--is-active {
                background-color: $light--base;

                &--dark {
                    background-color: $dark--base;
                }
            }

            @include screenSmallerThan($screen-xl) {
                @include hidden()
            }
        }
    }
}
