@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/keyframes";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".CB";

#{$selector} {
    &__content {
        @include text("meta");
    }

    &__buttons {
        display: flex;
        gap: $space-lg;
        flex-direction: column-reverse;
        justify-content: space-between;

        @include screenLargerThan($screen-sm) {
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }

        @include screenLargerThan($screen-lg) {
            gap: $space-xl;
            flex-direction: row;
            align-items: center;
            justify-content: right;
        }
    }

    &__button {
        @include screenLargerThan($screen-lg) {
            min-width: 150px;
        }

        &__custom {
            @include text("meta");
            display: block;
            text-align: center;
            font-size: 13px;
            cursor: pointer;
        }
    }
}
